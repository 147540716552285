:root {
  --white: #ffffff;
  --black: #2d3748;
  --purple: #4f52c1;
  --bright-purple: #6366f1;
  --washed-purple: #e7eafb;
  --dark-purple: #394db3;
  --dark-green: #078386;
  --washed-green: #e1f3f4;
  --bright-green: #0aaeb3;
  --light-green: #f5fcfc;
  --dark-pink: #bd3a7a;
  --washed-pink: #f9e8f1;
  --bright-pink: #ec4899;
  --washed-blue: #e0ecf8;
  --bright-blue: #3b9cf6;
  --bright-yellow: #eab506;
  --washed-yellow: #fef8e3;
  --dark-yellow: #c79a03;
  --light-gray: #8a9cb3;
  --dark-gray: #6f7176;
  --background-gray: #f1f2f4;
  --dark-orange: #ba531e;
  --bright-orange: #ff922d;
  --washed-orange: #fbdfbf;
}

html,
body {
  padding: 0;
  margin: 0;
  height: 100vh;
  background-color: var(--background-gray);
  font-family: Poppins, sans-serif;
  letter-spacing: 0.04em;
}

.app-page {
  max-height: 100vh;
  width: 100%;
  padding: 1rem 1rem 1rem 1.5rem;
  overflow: auto;
}

.authenticated-route-splash {
  padding: 1rem 1rem 1rem 1rem !important;
}

.app-h1 {
  font-size: 26px;
  font-weight: 700;
  line-height: 31px;
  letter-spacing: 0.02em;
  margin: 0;
}

.app-h2 {
  font-size: 18px;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: 0.02em;
}

.bg-white {
  background-color: #ffffff !important;
  background: #ffffff !important;
}

.p-1 {
  padding: 1rem !important;
}

.p-2 {
  padding: 1.5rem !important;
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: 1rem !important;
}

.mr-1 {
  margin-right: 1rem !important;
}

.mr-2 {
  margin-right: 1.5rem !important;
}

.pt-2 {
  padding-top: 1.5rem !important;
}

.pt-1 {
  padding-top: 1rem !important;
}

.pb-1 {
  padding-bottom: 1rem !important;
}

.mr-3 {
  margin-right: 2rem !important;
}

.ml-3 {
  margin-left: 2rem !important;
}

.ml-4 {
  margin-left: 2.5rem !important;
}

.ml-5 {
  margin-left: 3rem !important;
}

.mb-1 {
  margin-bottom: 1rem !important;
}

.mb-2 {
  margin-bottom: 1.5rem !important;
}

.mt-1 {
  margin-top: 1rem !important;
}

.mt-2 {
  margin-top: 1.5rem !important;
}

.mt-3 {
  margin-top: 2rem !important;
}

.mt-4 {
  margin-top: 2.5rem !important;
}

.mt-5 {
  margin-top: 3rem !important;
}

.ml-1 {
  margin-left: 1rem !important;
}

.ml-2 {
  margin-left: 1.5rem !important;
}

.pr-1 {
  padding-right: 1rem !important;
}

.pl-1 {
  padding-left: 1rem !important;
}

.pl-2 {
  padding-left: 1.5rem !important;
}

.pl-3 {
  padding-left: 2rem !important;
}

.pl-4 {
  padding-left: 2.5rem !important;
}

.pl-5 {
  padding-left: 3rem !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.sort-icon {
  vertical-align: bottom;
  color: var(--purple);
  stroke: var(--purple);
  stroke-width: 1;
  cursor: pointer;
}

.pointer {
  cursor: pointer;
}

.underline {
  text-decoration: underline;
}

.visibleOnPrint {
  display: none;
}

.text-white {
  color: var(--white) !important;
}

.no-margin {
  margin: 0 !important;
}

.bold {
  font-weight: 700;
}

.text-small {
  font-size: 12px;
}

/* override styles when printing */
@media print {
  #settingsBox {
    display: none !important;
  }

  .hideOnPrint {
    display: none !important;
  }

  .visibleOnPrint {
    display: block !important;
  }

  .category-card {
    height: 400px !important;
    padding-top: 10px !important;
    margin: auto;
  }

  .category-details {
    margin-top: 10px !important;
    margin-bottom: 0px !important;
  }

  .category-type {
    margin: 15px 0 15px 0 !important;
  }

  @page {
    size: auto; /* auto is the initial value */
    margin: 0mm; /* this affects the margin in the printer settings */
  }
}

.bizi-currency.full {
  font-size: 100% !important;
}

.bizi-currency {
  font-weight: 200;
  font-family: Inter, sans-serif;
  font-size: 80%;
}

.no-points {
  filter: grayscale(1);
}

.page-header {
  font-size: 32px;
  line-height: 48px;
  font-weight: 700;
  text-align: left;
  margin: 0 !important;
}

.middle {
  vertical-align: middle;
}

.img-top {
  vertical-align: top;
}

.img-bottom {
  vertical-align: bottom;
}

.text-center {
  text-align: center;
}

.request-btn {
  background-color: #ffffff !important;
  padding: 10px 25px !important;
  min-width: 160px;
  font-size: 16px;
}

.request-btn:hover {
  background-color: var(--washed-purple) !important;
}

.text-danger {
  color: var(--dark-pink) !important;
}

.period-card {
  width: 300px !important;
  height: 200px !important;
  border-radius: 28px !important;
}

.period-card-image {
  width: 300px !important;
  height: 200px !important;
  position: absolute;
  top: 14px;
}

.period-card * p {
  color: var(--white);
  font-weight: 700;
  z-index: 9;
  margin-bottom: 0 !important;
  margin-top: 0 !important;
}

.period-card-header {
  font-size: 22px;
  text-align: left;
  text-transform: uppercase;
}

.period-card-teacher {
  opacity: 0.75;
  font-size: 14px;
  text-align: left;
  margin-top: 5px;
  line-height: 21px;
}

.period-card-amount {
  font-size: 40px;
  text-align: right;
}

.period-card-text {
  font-size: 14px;
  text-align: right;
}

.modal-stats-style {
  font-size: 12px;
  line-height: 12px;
  margin: 0;
}

.swal2-container {
  font-family: Poppins, sans-serif !important;
}

.swal2-icon.swal2-error,
.swal2-x-mark {
  color: var(--bright-pink) !important;
  border-color: var(--bright-pink) !important;
}

.swal2-x-mark-line-right,
.swal2-x-mark-line-left {
  background-color: var(--bright-pink) !important;
}

.swal2-styled.swal2-confirm {
  background-color: var(--bright-purple) !important;
  border-color: var(--bright-purple) !important;
}

.swal2-success-line-long,
.swal2-success-line-tip {
  background-color: var(--bright-green) !important;
}

.swal2-success-ring {
  border-color: var(--bright-green) !important;
}
