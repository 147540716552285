.header-container {
  min-width: 256px;
  max-width: 256px;
  min-height: 100vh;
  overflow-y: auto;
  border-radius: 12px;
  border-right: 1px solid #ffffff1a;
  background: linear-gradient(0deg, #ffffff, #ffffff),
    linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1));
  transition: all 0.5s ease-in-out;
  display: flex;
  flex-direction: column;
}

.header-container.sm {
  min-width: 92px !important;
  max-width: 92px !important;
  padding: 0;
  transition: all 0.5s ease-in-out;
}

.header-logo-container {
  padding: 24px;
  max-height: 60px;
  white-space: nowrap;
  transition: all 0.5s ease-in-out;
}

.header-logo-container.sm {
  padding-left: 15px !important;
  transition: all 0.5s ease-in-out;
}

.user-fullname {
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  margin: 0 0 0 0 !important;
}

.user-role {
  font-size: 10px;
  font-weight: 500;
  line-height: 20px;
  margin: 0 0 0 0 !important;
}

.header-routes {
  margin-left: auto;
  margin-right: auto;
  max-width: 208px;
  min-width: 208px;
  padding-top: 24px;
}

.header-routes.sm {
  max-width: 92px;
  min-width: 92px;
}

.header-route {
  padding: 15px 22px 15px 22px;
  cursor: pointer;
  display: flex;
  gap: 12px;
  border-radius: 8px;
  white-space: wrap;
  user-select: none;
  margin-bottom: 2px;
}

.header-route.sm {
  width: 40px;
  height: 40px;
  margin: auto;
  transition: all 0.5s ease-in-out;
}

.header-route-icon.sm {
  margin: auto;
  margin-top: 5px;
  padding: 0;
}

.header-route > div > span {
  font-size: 18px;
  font-weight: 500;
}

.header-route:hover {
  background-color: #f6f6f6;
  opacity: 0.75;
}

.header-route.selected {
  background-color: #f6f6f6;
  color: var(--black);
}

.header-route-icon {
  padding-top: 4px;
  vertical-align: middle;
}

.slim-header-icon:hover {
  opacity: 0.75;
}

.slim-header-icon {
  position: absolute;
  top: 75px;
  left: 240px;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.5s ease-in-out;
}

.slim-header-icon.sm {
  left: 80px;
  top: 75px;
  transition: all 0.5s ease-in-out;
}

.hide {
  display: none;
}

@media screen and (max-width: 600px) {
  .header-container {
    min-width: 92px !important;
    max-width: 92px !important;
    padding: 0;
  }

  .header-route {
    width: 40px;
    height: 40px;
    padding: 10px;
    margin: auto;
  }

  .header-route-icon {
    margin: auto;
    margin-top: 5px;
    padding: 0;
  }

  .hide-small {
    display: none;
  }
}

@media screen and (max-height: 500px) {
  /* Apply the hidden class to the element */
  .hide-logout {
    display: none;
  }
}
